<script>
	import { tweened } from 'svelte/motion';
  export let original = 120 * 60; // TYPE NUMBER OF SECONDS HERE
  export let timer = tweened(original)

  // ------ dont need to modify code below
  setInterval(() => {
    if ($timer > 0) $timer--;
  }, 1000);

  $: hours = twoDigits(Math.floor($timer / 3600))
  $: minutes = twoDigits(Math.floor($timer / 60) % 60)
  // $: seconds = Math.floor($timer - minutes * 60)
  $: seconds = twoDigits(Math.floor($timer % 60))

  function twoDigits(value) {
    if(value.toString().length <= 1)
      {
        return "0"+value.toString();
      }
    return value.toString();
  }

</script>


{#if $timer < 1}
  <div class="bg-danger p-3">
    waktu habis
  </div> 
{:else if $timer < 180} 
  <div class="bg-warning p-3">
    {minutes}:{seconds}
  </div>  
{:else}
  <div class="bg-success p-3">
    {minutes}:{seconds}
  </div>  
{/if}
